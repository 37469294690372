<template>
  <div>
    <v-row
      :style="{
        borderRadius: '10px',
        margin: '4px',
        backgroundColor: $cv('primary') + '20',
      }"
    >
      <v-col cols="auto" md="5" class="py-8 ml-5">
        <span class="text-body-1 justify-center"><b>SELECCIONE UN MÉTODO DE PAGO</b></span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" md="5" class="px-5">
        <v-select
          v-model="metodoPago"
          :items="filterItems"
          density="comfortable"
          label="Seleccionar"
          single-line
          @change="habilitarCompra"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" md="12">
        <v-card
          border
          class=""
          :style="{
            borderRadius: '10px',
            margin: '4px',
            backgroundColor: $cv('primary') + '20',
          }"
          ><h2 class="">
            {{ tituloseccion }} 
          </h2>
          <ClienteAduana
            v-if="sourceVentaElementosAduanaSt"
            :cliente="clienteLocal"
          ></ClienteAduana>
          <hr class="" />
          <div v-if="carrito.length == 0" class="carritoMsg__div">
            <p>Sin Productos en el carrito</p>
            <p>Seleccione los Productos que desea comprar</p>
          </div>
          <div class="carrito__div">
            <div v-for="(item, index) in carrito" :key="index">
              <div class="div__vfor">
                <v-col cols="auto" md="2" class="text-center">
                  
                    {{ item.codigo }}
                  
                </v-col>
                <v-col cols="auto" md="5">
                  {{ item.nombre }}

                    Precio por Unidad: ${{
                      item.precio ? $formatMoney(item.precio) : ""
                    }}
                </v-col>
                <v-col cols="auto" md="2">
                  Cantidad: {{ item.cantidad }}
                </v-col>
                <v-col cols="auto" md="2" class="">
                  
                    ${{ item.subtotal ? $formatMoney(item.subtotal) : "" }}
                 
                </v-col>
                <v-col cols="auto" md="1" class="action__detalle">
                  <v-icon title="Editar" @click="editProductos(item)"
                    >mdi-pencil-box</v-icon
                  >
                  <v-icon title="Eliminar" @click="eliminarProducto(item)"
                    >mdi-trash-can</v-icon
                  >
                </v-col>
              </div>
              <hr v-if="index + 1 < carrito.length" />
            </div>
          </div>

          <div v-show="carrito.length != 0">
            <hr class="" />
            <div class="div_totales py-4">
              <div>
                <span class="py-4 px-6 my-0 text-end font-weight-bold"> 
                  Subtotal: $ {{ total ? $formatMoney(total) : "" }}
                </span>
              </div>
              <div v-if="isProduct">
                <span class="py-4 px-6 my-0 text-end font-weight-bold">
                  {{
                    tipogasto === null
                      ? "Gastos pedido: Seleccione Metodo de Pago"
                      : `${tipogasto} ${gasto}`
                  }}
                </span>
              </div>
              <div>
                <span class="py-3 px-6 my-0 text-end font-weight-bold">
                  Total: $ {{ resultado ? $formatMoney(resultado) : "" }}
                </span>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <template v-if="isProduct">
        <AgregarProductos
          v-if="dialog"
          :item="editedItem"
          :action="accionProducto"
          @terminar="dialog = !dialog"
        />
      </template>
      <template v-else>
        <AgregarSaldoCtaCte
          v-if="dialog"
          :item="editedItem"
          :action="accionProducto"
          @terminar="dialog = !dialog"
        />
      </template>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-end">
        <router-link
          :to="this.$route.path  === '/detalle_pedido_adquirir_saldo' ? routeProductos : cartEditPath"
        >
          <BaseButton
            color="btnSecondary"
            variant="outlined"
            class="mr-4"
          >
            Cancelar
          </BaseButton>
        </router-link>
        <router-link
          :to="routePedidoFinalizado"
          :style="{ pointerEvents: habilitaCompra }"
        >
          <BaseButton
            color="btnPrimary"
            class="mr-1"
          >
            Confirmar
          </BaseButton>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import AgregarProductos from "@/views/dashboard/components/secundarios/AgregarProductos.vue/";
import ClienteAduana from "@/views/dashboard/components/secundarios/ClienteAduana.vue";
import AgregarSaldoCtaCte from "@/views/dashboard/components/secundarios/AgregarSaldoCtaCte.vue/";
import { BaseButton } from "../../components/design-system";

export default {
  components: {
    AgregarProductos,
    ClienteAduana,
    AgregarSaldoCtaCte,
    BaseButton,
  },

  computed: {
    ...mapState("carrito", ["carrito", "total"]),
    cartEditPath() {
      if (this.clienteLocal && this.sourceVentaElementosAduanaSt) {
        return {
          name: "elementos_aduana_venta_seleccion",
          params: {
            cliente: this.clienteLocal,
            sourceVentaElementosAduana: this.sourceVentaElementosAduanaSt,
          },
        };
      } else {
        return this.$store.state.cartEditPath;
      }
    },
    ...mapState("aduana", [
      "clienteLocal",
      "sourceVentaElementosAduanaSt",
    ]),

    filterItems() {
      if (this.sourceVentaElementosAduanaSt) {
        return this.itemsPago.filter(
          (item) => item !== "B to B (Tranferencia bancaria)"
        );
      } else {
        return this.itemsPago;
      }
    },
  },

  data: (vm) => ({
    routeProductos: "",
    routePedidoFinalizado: "",
    isProduct: true,
    itemsPago: [
      "Boleta Cheque Banco Nación",
      "Boleta Efectivo Banco Nación",
      "B to B (Tranferencia bancaria)",
    ],
    metodoPago: null,
    tipogasto: null,
    gasto: null,
    resultado: 0,
    habilitaCompra: "none",
    tituloseccion: "",
    dialog: false,
    editedItem: {},
    accionProducto: "",
    previousPath: "",
    sourceVentaElementosAduana: "",
  }),

  methods: {
    ...mapMutations("carrito", ["setTramite", "vaciarCarrito", "removeProducto"]),
    habilitarCompra() {
      console.log(typeof this.total);
      if (this.metodoPago == "" || this.carrito.length == 0) {
        this.habilitaCompra = "none";
        return;
      }
      this.calculoGastos();
      /*if (
        this.metodoPago == "Boleta Cheque Banco Nación" ||
        this.metodoPago == "Boleta Efectivo Banco Nación"
      ) {
        this.tipogasto = "Gastos bancarios:";
        this.gasto = `${(this.total * 5) / 100}`;
        return (this.habilitaCompra = "");
      }
      this.tipogasto = "Gastos de transferencia";
      this.gasto = `${(this.total * 2.5) / 100}`;*/
      this.habilitaCompra = "";
      this.setTramite({ tipogasto: this.tipogasto, gasto: this.gasto });
    },
    calculoGastos() {
      if (this.metodoPago) {
        if (this.metodoPago == "Saldo a Favor") {
          this.resultado = this.total;
          return "";
        }
        if (
          this.metodoPago == "Boleta Cheque Banco Nación" ||
          this.metodoPago == "Boleta Efectivo Banco Nación"
        ) {
          this.tipogasto = "Gastos bancarios:";
          this.gasto = `${(this.total * 5) / 100}`;
          this.resultado = this.total * 1 + this.gasto * 1;
          return (this.habilitaCompra = "");
        } else {
          this.resultado = this.total;
        }
        this.tipogasto = "Gastos de transferencia";
        this.gasto = `${(this.total * 2.5) / 100}`;
        this.resultado = this.total * 1 + this.gasto * 1;
        return (this.habilitaCompra = "");
      } else if (this.metodoPago == "B to B (Tranferencia bancaria)") {
        this.tipogasto = "Gastos de transferencia";
        this.gasto = `${(this.total * 2.5) / 100}`;
        this.resultado = this.total * 1 + this.gasto * 1;
        return (this.habilitaCompra = "");
      } else {
        this.tipogasto = null;
        this.gasto = null;
        this.resultado = this.total;
        return (this.habilitaCompra = "none");
      }
    },
    editProductos(item) {
      this.editedItem = Object.assign({}, item);
      this.accionProducto = "EDITAR PRODUCTO";
      this.dialog = true;
    },
    eliminarProducto(item) {
      Swal.fire({
        title: `Desea eliminar el Producto?`,
        text: `${item.cantidad} Unidades de ${item.nombre}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.removeProducto(item);
          Swal.fire({
            title: `Se han Eliminado ${item.cantidad} Unidades de "${item.nombre}"`,
            icon: "success",
          });
        }
      });
    },
  },

  created(){
    const previousPath = this.$route.params.previousPath;
  },
  mounted() {
    switch (this.$route.path) {
      case "/detalle_pedido_adquirir_saldo":
        this.routeProductos = "/adquirir_saldo_cta_cte";
        this.routePedidoFinalizado = "/PedidoFinalizadoAdquirirSaldo";
        this.itemsPago = ["Saldo a Favor"];
        this.isProduct = false;
        break;
      case "/elementos_aduana_venta_detalle_pedido":
        this.routeProductos = this.previousPath;
        this.routePedidoFinalizado = "/elementos_aduana_venta_pedido_finalizado";
        break;
      case "/elementos_aduana_compra_detalle_pedido":
        this.routeProductos = this.previousPath;
        this.routePedidoFinalizado = "/elementos_aduana_compra_pedido_finalizado";
        break;
      default:
        this.routePedidoFinalizado = "/PedidoFinalizado";
        this.routeProductos = this.previousPath;
        break;
    }
    this.tituloseccion = "Resumen de pedido";
    this.resultado = this.total;
  },
  watch: {
    carrito() {
      this.habilitarCompra();
    },
  },
  
};
</script>

<style>
h2 {
  padding: 1rem 2rem;
}
.carritoMsg__div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 9rem;
  font-weight: bold;
}
.div__vfor {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
.carrito__div {
  overflow: auto;
  max-height: 28.5rem;
}
.metodoPado__row {
  display: flex;
  align-items: center;
  border-radius: 1rem;
}
.div_totales {
  display: flex;
  justify-content: space-between;
}
.vcol__comprar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.routerLink_Comprar {
  background-color: #011f5b;
  color: white !important;
  padding: 1rem 4rem;
  text-decoration: none;
  border-radius: 0.5rem;
}
.action__detalle {
  display: flex;
  justify-content: space-around;
}
</style>
